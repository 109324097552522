<template>
	<v-container fluid>
		<v-row>
			<v-col class="pb-0">
				<v-card dark>
					<v-img
						height="200px"
						src="../assets/escuela.jpg"
						gradient="to top right, var(--v-primary-base), rgba(25,32,72,.7)"
					>
						<v-layout fill-height class="d-flex justify-center align-center text-h6">
							Gestión de Escuela EP N°23
						</v-layout>
					</v-img>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-6 col-sm-3">
				<v-card :to="{name: 'alumnos'}">
					<v-card-title class="justify-center">
						<v-icon x-large>mdi-school</v-icon>
					</v-card-title>
					<v-card-title class="justify-center">
						Buscador de Alumnos
					</v-card-title>
				</v-card>
			</v-col>
			<v-col class="col-6 col-sm-3">
				<v-card :to="{name: 'cedulas'}">
					<v-card-title class="justify-center">
						<v-icon x-large>mdi-file</v-icon>
					</v-card-title>
					<v-card-title class="justify-center">
						Buscador de Cedulas
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>