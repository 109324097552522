<template>  
    <v-app-bar app dense clipped-left color="primary" dark>
        <v-app-bar-nav-icon @click="toggleNavigation"></v-app-bar-nav-icon>
    </v-app-bar>
</template>

<script>
export default {
    methods: {
        toggleNavigation() {
            this.$store.commit('setNavigation', !this.$store.state.showNavigation);
        }
    }
}
</script>

<style>

</style>