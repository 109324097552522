<template>
    <v-card-text>
        <template v-for="(nota, index) in alumno['NOTAS']">
            <v-alert v-if="nota.note !== undefined" :key="index"
                type="warning"
            >
                {{nota.note}}
            </v-alert>
        </template>
    </v-card-text>
</template>

<script>
export default {
    props: {
        alumno: Object
    }
}
</script>

<style>

</style>