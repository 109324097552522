import fetcher from "@/plugins/fetcher"
import cedulas_map_3_3_2022 from './cedulas-json/cedulas-map-3-3-2022.json';

const SHEET_ID = "1gARj0l-TlgHw0DFOsAgpYlE9P1hWiYDF5ItW_BDm4zk"
const API_KEY = "AIzaSyA_trodNny9ZU19MyL4MBf99PJJhWWAbOA"
const API_GOOGLE = "https://content-sheets.googleapis.com/v4/spreadsheets/"

const RANGE = "A2:ZZ";

export default {
    async getCedulas() {
        let data = await fetcher.fetch({
            method: 'GET',
            url: `${API_GOOGLE}${SHEET_ID}/values/${RANGE}?key=${API_KEY}`
        });

        let cedulas = mapCedulas(data.values, cedulas_map_3_3_2022);

        return cedulas;
    },
    getDatetimeFormat() {
        return "D/MM/YYYY H:mm:ss"
    },
    getDatetimeURLFormat() {
        return "YYYYMMDDHHmmss"
    }
}

function mapCedulas(cedulas_values, cedulas_mapper) {

    let cedulas = [];

    cedulas_values.forEach(cedula_values => {
        let cedula = {};

        for (let [key, value] of Object.entries(cedulas_mapper)) {

            let deep_object = {};
            for (let [deep_key, deep_value] of Object.entries(value)) {
                deep_object[deep_key] = formatCedulaValue(cedula_values[deep_value]);
            }

            cedula[key] = deep_object;
        }
        
        cedulas.push(cedula);
    });

    return cedulas;
}
function formatCedulaValue(cedula_value) {
    switch (typeof cedula_value) {
        case 'string':
            return cedula_value.toUpperCase();
        default:
            return cedula_value;
    }
}