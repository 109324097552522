export default {
    fromURL(filters, route_query) {
        let query_filters = Object.entries(route_query);

        query_filters.forEach(query => {
            let key = 0;
            let value = 1;

            let filter = filters[query[key]];

            switch(typeof filter) {
                case 'string':
                case 'number':
                    filters[query[key]] = query[value] || "";
                    break
                case 'boolean':
                    filters[query[key]] = query[value] == "true";
                    break
                case 'object':
                    filters[query[key]] = Array.isArray(query[value]) ? query[value] : [query[value]];
            }
        })
    },
    toURL(filters, route_query, router) {
        let filters_keys = Object.keys(filters);

        let filter_key = undefined;

        for(let i = 0; i < filters_keys.length; i++) {
            filter_key = filters_keys[i];

            if(filters[filter_key] !== route_query[filter_key])
                continue;
        }
        
        router.replace({
            query: filters
        }).catch(()=>{});
    }
}