<template>
	<v-navigation-drawer app clipped expand-on-hover mini-variant v-model="navigation">
		<v-layout column fill-height>
			<v-list dense class="pb-0">
				<v-list-item class="px-2">
					
					<v-list-item-avatar>
					<v-img src="../assets/escudo.png"></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>EP N°23</v-list-item-title>
						<v-list-item-subtitle>Ricardo Güiraldes</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<v-list dense nav>
				<v-list-item 
					v-for="route in routes" 
					:key="route.name"
					:to="route.path == '' ?  '/' : route.path"
				>
					<v-list-item-icon>
						<v-icon>{{ route.meta.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ route.meta.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-spacer></v-spacer>

			<v-list dense nav>
				<v-list-item link @click="$store.commit('setToken', '')">
					<v-list-item-icon>
						<v-icon>mdi-logout</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Cerrar Sesión</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-layout>
	</v-navigation-drawer>
</template>

<script>
export default {
	data: () => ({
		navigation: null,
	}),
	methods: {
		
	},
	watch: {
		'navigation'(navigation) {
			this.$store.commit('setNavigation', navigation)
		},
		'$store.state.showNavigation'(showNavigation) {
			this.navigation = showNavigation;
		}
	},
	computed: {
		routes() {
			return this.$router.matcher.getRoutes().filter(route => {
				return route.meta.icon !== undefined;
			})
		}
	},
	created() {
		this.$store.commit('setNavigation', null);
	}
}
</script>

<style>

</style>