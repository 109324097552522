<template>
	<div>
		<v-overlay v-model="show" opacity="100" color="primary"></v-overlay>
		<v-dialog v-model="show" persistent max-width="600" hide-overlay>
			<v-card :loading="loading">
				<v-form @submit.prevent="login">
					<v-card-title>Iniciar sesión</v-card-title>
					<v-card-text>
						<p>Para continuar debe ingresar usuario y contraseña</p>
						<v-alert dense type="error" v-if="errorMessage != ''">
							{{errorMessage}}
						</v-alert>
						<v-text-field v-model="username" label="Usuario" dense outlined></v-text-field>
						<v-text-field v-model="password" label="Contraseña" dense outlined hide-details="" type="password"></v-text-field>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn type="submit" color="primary" text>Iniciar Sesión<v-icon right>mdi-login</v-icon></v-btn>
					</v-card-actions>
				</v-form>
				<v-overlay absolute v-model="loading" opacity="100">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-overlay>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import fetcher from '../plugins/fetcher';
export default {
	data: () => ({
		show: false,
		loading: false,
		username: "",
		password: "",
		errorMessage: "",
	}),
	methods: {
		async login() {

			this.loading = true;
			this.errorMessage = "";

			let data = await fetcher.fetch({
				method: 'POST',
				url: '/api/login', 
				body: {
					name: this.username,
					password: this.password
				}
			});

			let token = data.token;

			if(token !== undefined) {
				
				this.$store.commit('setToken', token);

				this.show = false;
			}

			this.loading = false;
		},
		checkExpiredToken() {
			let token = this.$store.getters.getParsedToken;
			let today = new Date();

			if(token === null) {
				this.noToken();
				return;
			}
			if(token.exp * 1000 < today.getTime()) {
				this.noToken();
			}			
		},
		noToken() {
			this.show = true;
			this.$store.commit('setToken', "");
		}
	},
	errorCaptured(err) {
		if(err.type != "HTTP")
			return true;
		
		this.loading = false;

		console.log(err.status)
		
		switch(err.status) {
			case 401: 
				this.errorMessage = "Usuario y/o contraseña incorrectas"; 
				break;
			default: 
				this.errorMessage = "Ha ocurrido un error"; 
				break;
		}

		return false;
    },
	created() {
		this.checkExpiredToken();
	},
	watch: {
		'$store.state.token'() {
			this.checkExpiredToken();
		}
	}
}
</script>

<style>

</style>