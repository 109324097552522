<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Listado de Cedulas
						<v-btn icon color="primary" @click="loadData"><v-icon>mdi-reload</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-checkbox
                            class="pr-2 mt-0 pt-0"
                            :on-icon="'mdi-arrow-up'" 
                            :off-icon="'mdi-arrow-down'"
                            hide-details
                            v-model="filters.order_asc"
                        ></v-checkbox>
                        <v-select 
                            label="Ordenar por" 
                            prepend-inner-icon="mdi-filter"  
                            hide-details outlined dense
                            :items="order_by"
                            v-model="filters.order_by"
                        ></v-select>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="align-center">
                            <v-col>
                                <v-text-field 
                                    label="Nombre" 
                                    prepend-inner-icon="mdi-magnify"
                                    hide-details outlined dense
                                    v-model="filters.nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-12 col-sm-3">
                                <v-text-field 
                                    label="Curso" 
                                    prepend-inner-icon="mdi-magnify"  
                                    hide-details outlined dense
                                    v-model="filters.curso"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-12 col-sm-3">
                                <v-text-field 
                                    label="DNI" 
                                    prepend-inner-icon="mdi-magnify"  
                                    hide-details outlined dense
                                    v-model="filters.dni"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-12 col-sm-2">
                                <v-checkbox
                                    class="pr-2 mt-0 pt-0"
                                    label="SIN CARGAR"
                                    hide-details
                                    v-model="filters.faltan_cargar"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card outlined>
                                    <v-data-table
                                        :loading="loading"
                                        :headers="headers"
                                        :items="cedulas_filtered"
                                        class="clickable-row"
                                        @click:row="clickCedula"
                                    >
                                        <template v-slot:[`item.fecha`]="{ item }">
                                            {{item.inscripcion.datetime}}
                                        </template>
                                        <template v-slot:[`item.curso`]="{ item }">
                                            {{item.inscripcion.año}}{{item.inscripcion.division}}
                                        </template>
                                        <template v-slot:[`item.nombre_completo`]="{ item }">
                                            {{item.datos_del_alumno.apellidos}}, {{item.datos_del_alumno.nombres}}
                                        </template>
                                        <template v-slot:[`item.cargado`]="{ item }">
                                            <v-chip v-if="item.cargado"
                                                color="success" dark
                                            >
                                                CARGADO
                                            </v-chip>
                                            <v-chip v-else color="error" dark>
                                                SIN CARGAR
                                            </v-chip>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
					<v-overlay v-model="loading" absolute>
						<v-progress-circular indeterminate></v-progress-circular>
					</v-overlay>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CedulasHelper from "@/utils/cedulas-helper"
import AlumnosHelper from "@/utils/alumnos-helper";
import Filters from "@/utils/filters";
import Moment from 'moment'

export default {
    data: () => ({
        loading: false,
        filters: {
            nombre: "",
            dni: "",
            curso: "",
            faltan_cargar: false,
            order_by: "FECHA DE CARGA",
            order_asc: false
        },
        order_by: [
            "FECHA DE CARGA",
            "CURSO",
            "NOMBRE"
        ],

        cedulas: [],
		headers: [
            {text: "FECHA DE CARGA", value: "fecha", sortable: false, align: "center"},
			{text: "CURSO", value: "curso", sortable: false, align: "center"},
			{text: "NOMBRE", value: "nombre_completo", sortable: false},
            {text: "DOCUMENTO", value: "datos_del_alumno.documento_numero", sortable: false},
            {text: "CARGADO", value: "cargado", sortable: false, align: "center"}
		],

        alumnos: [],
        cursos: [],
    }),
    methods: {
        async loadData() {
            this.loading = true;

            this.cursos = await AlumnosHelper.getCursos();
            this.alumnos = await AlumnosHelper.getAlumnos(this.cursos);
            this.cedulas = await CedulasHelper.getCedulas();

            this.findCedulasCargados();

            this.loading = false;
        },
        findCedulasCargados() {
            for(let i = 0; i < this.cedulas.length; i++) {
                let cedula = this.cedulas[i];

                let alumno = this.findAlumno(cedula.datos_del_alumno.documento_numero);

                if(alumno == null) 
                    continue;

                cedula.cargado = alumno;
            }
        },
        findAlumno(dni) {
            dni = dni.replaceAll('.', '');
            for(let i = 0; i < this.alumnos.length; i++) {
                let alumno = this.alumnos[i];

                if(alumno["DNI"] == dni) {
                    return alumno;
                }
            }
            return null;
        },
		clickCedula(item) {
            let datetimeURL = Moment(item.inscripcion.datetime, this.datetimeformat)
                .format(this.datetimeURLformat);

			this.$router.push({name: 'cedula', params: {datetimeURL: datetimeURL}})
		},
		textCompare(textEvery, textSome) {
			// Separar los textos con espacios y/o comas
			// luego normalizar (eliminar acentos)
			// textEvery debe encontrar cada palabra
			// textSome debe encontrar al menos una
			let delimiters = /[\s,]+/;
			let words1 = textEvery.split(delimiters);
			let words2 = textSome.split(delimiters);

			return words1.every(word1 => {
				word1 = word1.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
				return words2.some(word2 => {
					word2 = word2.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
					return (word1.includes(word2) || word2.includes(word1)) && word2 !== ""
				})
			})
		},
    },
    computed: {
        cedulas_filtered() {
            let cedulas = this.cedulas;

            cedulas = cedulas.filter(cedula => {
                let nombre = cedula.datos_del_alumno.apellidos + ", " + cedula.datos_del_alumno.nombres;
                let dni = cedula.datos_del_alumno.documento_numero;
                let curso = cedula.inscripcion.año + cedula.inscripcion.division;
                let cargado = cedula.cargado;

                let nombreFilter = this.textCompare(this.filters.nombre, nombre) || this.filters.nombre === "";
                let dniFilter = this.textCompare(this.filters.dni, dni) || this.filters.dni === "";
                let cursoFilter = this.textCompare(this.filters.curso, curso) || this.filters.curso === "";
                let cargadoFilter = cargado == undefined || !this.filters.faltan_cargar;

                return nombreFilter && dniFilter && cursoFilter && cargadoFilter;
            })

            cedulas = cedulas.sort((a, b) => {
                switch(this.filters.order_by) {
                    case "FECHA DE CARGA": {
                        let datetimeA = Moment(a.inscripcion.datetime, this.datetimeformat);
                        let datetimeB = Moment(b.inscripcion.datetime, this.datetimeformat);

                        if(this.filters.order_asc)
                            return datetimeA.isAfter(datetimeB) ? 1 : -1;
                        else
                            return datetimeA.isBefore(datetimeB) ? 1 : -1;
                    }
                    case "CURSO": {
                        let cursoA = a.inscripcion.año + a.inscripcion.division;
                        let cursoB = b.inscripcion.año + b.inscripcion.division;

                        if(this.filters.order_asc)
                            return cursoA < cursoB ? 1 : -1;
                        else
                            return cursoA > cursoB ? 1 : -1;
                    }
                    case "NOMBRE": {
                        let nombreA = a.datos_del_alumno.apellidos + ", " + a.datos_del_alumno.nombres;
                        let nombreB = b.datos_del_alumno.apellidos + ", " + b.datos_del_alumno.nombres;

                        if(this.filters.order_asc)
                            return nombreA < nombreB ? 1 : -1;
                        else
                            return nombreA > nombreB ? 1 : -1;
                    }
                }
            });

            return cedulas;
        },
        datetimeformat() {
            return CedulasHelper.getDatetimeFormat();
        },
        datetimeURLformat() {
            return CedulasHelper.getDatetimeURLFormat();
        }
    },
    watch: {
		filters: {
			handler(filters) {
				Filters.toURL(filters, this.$route.query, this.$router);
			},
			deep: true
		}
    },
    async created() {
        await this.loadData();
		Filters.fromURL(this.filters, this.$route.query);
    }
}
</script>

<style>

</style>