<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<v-card :loading="loading">
					<v-card-title>
						Filtros
						<v-spacer></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="primary" 
									v-bind="attrs" v-on="on" 
									@click="loadData"
								><v-icon>mdi-reload</v-icon></v-btn>
							</template>
							Recargar Alumnos
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="error" 
									v-bind="attrs" v-on="on" 
									@click="clearFilters"
								><v-icon>mdi-close</v-icon></v-btn>
							</template>
							Resetear todos los Filtros
						</v-tooltip>
					</v-card-title>
					<v-card-text>
						<v-text-field label="Nombre" dense outlined hide-details
							v-model="filters.nombre"
						></v-text-field>
						<v-text-field label="DNI" class="mt-4" dense outlined hide-details
							v-model="filters.dni"
						></v-text-field>
						<v-text-field label="Telefónos" class="mt-4" dense outlined hide-details
							v-model="filters.telefonos"
						></v-text-field>
						<v-text-field label="Dirección" class="mt-4" dense outlined hide-details
							v-model="filters.direccion"
						></v-text-field>
						<v-text-field label="Estado" class="mt-4" dense outlined hide-details
							v-model="filters.estado"
						></v-text-field>
						<v-select label="Curso" class="mt-4" outlined hide-details multiple chips
							:items="cursos"
							v-model="filters.cursos"
						></v-select>
						<v-checkbox label="Tiene Hermanos" hide-details
							value="true"
							v-model="filters.hermanos"
						></v-checkbox>
						<v-checkbox label="Tiene Notas" hide-details
							value="true"
							v-model="filters.notas"
						></v-checkbox>
					</v-card-text>
					<v-overlay v-model="loading" absolute>
						<v-progress-circular indeterminate></v-progress-circular>
					</v-overlay>
				</v-card>
			</v-col>
			<v-col class="col-12 col-md-12 col-lg-10">
				<v-card>
					<v-data-table
						:loading="loading"
						:headers="headers"
						:items="alumnos_filtered"
						class="clickable-row"
						@click:row="clickAlumno"
						dense
					>
						<template v-slot:[`item.HERMANOS`]="{ item }">
							<v-chip v-if="item['HERMANOS']" color="primary" dark>
								{{item['HERMANOS'].split(/[\s,]+/).length}}
							</v-chip>
						</template>
						<template v-slot:[`item.ALUMNES`]="{ item }">
							<v-tooltip bottom v-if="item['NOTAS']" color="warning">
								<template v-slot:activator="{ on, attrs }">
									<v-badge dot left inline color="warning">
										<span v-bind="attrs" v-on="on">{{item["ALUMNES"]}}</span>
									</v-badge>
								</template>
								<template v-for="(nota, i) in item['NOTAS']">
									<div v-if="nota.note" :key="i">
										<v-icon left dark>mdi-alert</v-icon>
										{{nota.note}}
									</div>
								</template>
							</v-tooltip>
							<span v-else>{{item["ALUMNES"]}}</span>
						</template>
					</v-data-table>
					<v-overlay v-model="loading" absolute>
						<v-progress-circular indeterminate></v-progress-circular>
					</v-overlay>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AlumnosHelper from '../utils/alumnos-helper'
import Filters from "@/utils/filters";

export default {
	data: () => ({
		loading: false,

		filters: {
			nombre: "",
			dni: "",
			estado: "",
			telefonos: "",
			direccion: "",
			cursos: [],
			hermanos: null,
			notas: null
		},

		cursos: [],
		headers: [
			{text: "CURSO", value: "CURSO", sortable: false, align: "center"},
			{text: "N", value: "N°", sortable: false, align: "center"},
			{text: "NOMBRE", value: "ALUMNES", sortable: false},
			{text: "DNI", value: "DNI", sortable: false, align: "center"},
			{text: "TELEFONOS", value: "TELEFONOS", sortable: false, align: "center"},
			{text: "DIRECCION", value: "DIRECCION", sortable: false},
			{text: "ESTADO", value: "ESTADO", sortable: false, align: "center"},
			{text: "HERMANOS", value: "HERMANOS", sortable: false, align: "center"}
		],
		alumnos: [],
	}),
	methods: {
		async loadData() {
			this.loading = true;

			this.cursos = await AlumnosHelper.getCursos();
			this.alumnos = await AlumnosHelper.getAlumnos(this.cursos);

			this.loading = false;
		},
		textCompare(textEvery, textSome) {
			if(textEvery === undefined || textSome === undefined)
				return false;
			// Separar los textos con espacios y/o comas
			// luego normalizar (eliminar acentos)
			// textEvery debe encontrar cada palabra
			// textSome debe encontrar al menos una
			let delimiters = /[\s,]+/;
			let words1 = textEvery.split(delimiters);
			let words2 = textSome.split(delimiters);

			return words1.every(word1 => {
				word1 = word1.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
				return words2.some(word2 => {
					word2 = word2.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
					return (word1.includes(word2) || word2.includes(word1)) && word2 !== ""
				})
			})
		},
		clickAlumno(item) {
			this.$router.push({name: 'alumno', params: {id: item["ID"]}})
		},
		clearFilters() {
			this.filters = {
				nombre: "",
				dni: "",
				estado: "",
				telefonos: "",
				direccion: "",
				cursos: [],
				hermanos: null,
				notas: null
			}
		}
	},
	computed: {
		alumnos_filtered() {
			let alumnos = this.alumnos;

			alumnos = alumnos.filter(alumno => {
				let cursoFilter = this.filters.cursos.includes(alumno["CURSO"]) || this.filters.cursos.length === 0;
				let nombreFilter = this.textCompare(this.filters.nombre, alumno["ALUMNES"]) || this.filters.nombre === "";
				let dniFilter = this.textCompare(this.filters.dni, alumno["DNI"]) || this.filters.dni === "";
				let telefonosFilter = this.textCompare(this.filters.telefonos, alumno["TELEFONOS"]) || this.filters.telefonos === "";
				let direccionFilter = this.textCompare(this.filters.direccion, alumno["DIRECCION"]) || this.filters.direccion === "";
				let estadoFilter = this.textCompare(this.filters.estado, alumno["ESTADO"]) || this.filters.estado === "";
				let hermanosFilter = this.filters.hermanos !== null ? alumno["HERMANOS"] : true;
				let notasFilter = this.filters.notas !== null ? (alumno["NOTAS"] !== undefined && alumno["NOTAS"].length > 0) : true;

				return 	cursoFilter && nombreFilter && dniFilter &&
						telefonosFilter && direccionFilter && estadoFilter && 
						hermanosFilter && notasFilter;				
			})

			return alumnos;
		}
	},
	watch: {
		// Save filters change in query, so when it reloads
		// it remembers

		// alternatively I could use store
		filters: {
			handler() {
				Filters.toURL(this.filters, this.$route.query, this.$router);
			},
			deep: true
		}
	},
	async created() {
		await this.loadData();

		Filters.fromURL(this.filters, this.$route.query, this.$router);
	}
}
</script>

<style>
.clickable-row {
	cursor: pointer;
}
</style>