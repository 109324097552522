import fetcher from "@/plugins/fetcher"

const SHEET_ID = "19rogM568VWxx5Tg_8R1GB6BmSndJLE0BustHbiln0Es"
const API_KEY = "AIzaSyA_trodNny9ZU19MyL4MBf99PJJhWWAbOA"
const API_GOOGLE = "https://content-sheets.googleapis.com/v4/spreadsheets/"

export default {
    async getCursos() {
        let data = await fetcher.fetch({
            method: 'GET',
            url: `${API_GOOGLE}${SHEET_ID}?key=${API_KEY}`
        });

        let cursos = [];

        for(let i = 0; i < data.sheets.length; i++) {
            let sheet = data.sheets[i];
            cursos.push(sheet.properties.title);
        }

        return cursos;
    },
    async getAlumnos(cursos) {

        let ranges = "";

        cursos.forEach(curso => {
            ranges += `ranges=${curso}!A:Z&`;
        });

        let data = await fetcher.fetch({
            method: 'GET',
            url: `${API_GOOGLE}${SHEET_ID}/values:batchGet?${ranges}access_token=${API_KEY}&key=${API_KEY}`
        });

        return await sheetCellsToObjects(data.valueRanges, cursos);
    }
}

async function getNotas(cursos) {

    let ranges = "";

    cursos.forEach(curso => {
        ranges += `ranges=${curso}!B:Z&`;
    });

    // obtengo notas especificando
    // 'sheets/data/rowData/values/norte'
    // https://developers.google.com/sheets/api/reference/rest/v4/spreadsheets/cells#CellData
    // https://stackoverflow.com/questions/53473142/google-sheets-apiv4-getting-notes-from-cell
    let response = await fetcher.fetch({
        method: 'GET',
        url: `${API_GOOGLE}${SHEET_ID}/?fields=sheets%2Fdata%2FrowData%2Fvalues%2Fnote&${ranges}access_token=${API_KEY}&key=${API_KEY}`
    });

    let notasPorCurso = response.sheets.map((sheet, index) => {
        return {
            curso: cursos[index],
            notas: sheet.data[0]
        };
    });

    return notasPorCurso;
}

async function sheetCellsToObjects(ranges, cursos) {
    let keys = [];
    let objects = [];

    let notas = await getNotas(cursos);

    //console.log(notas);

    for(let r = 0; r < ranges.length; r++) {

        let range = ranges[r];
        let values = ranges[r].values;
        let curso = range.range.substring(1, 4);

        for(var col = 0; col < values[0].length; col++) {
            let key = range.values[0][col];

            if(keys.includes(key))
                continue;

            keys.push(key);
        }

        for(let row = 1; row < values.length; row++) {
            let object = {};

            object['CURSO'] = curso;


            if (notas[r].notas.rowData !== undefined && notas[r].notas.rowData[row] !== undefined) {
                object['NOTAS'] = notas[r].notas.rowData[row].values;
            } 

            for(let col = 0; col < values[row].length; col++) {
                if(col >= keys.length)
                    continue;
                let value = values[row][col];

                object[keys[col]] = value;
            }
            objects.push(object);
        }
    }
    
    return objects;
}