<template>
	<v-container fluid :key="id">
		<v-card :loading="loading">
			<v-toolbar flat>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="mr-2" icon v-bind="attrs" v-on="on" @click="$router.back()">
							<v-icon>mdi-arrow-left</v-icon>
						</v-btn>
					</template>
					Volver hacia atras
				</v-tooltip>
				<v-toolbar-title v-if="!loading">{{alumno["CURSO"]}} - {{alumno["ALUMNES"]}}</v-toolbar-title>
				<v-spacer></v-spacer>
				<div>
					<v-tooltip
						v-model="cedula_tooltip_show"
						:color="cedula_tooltip_color"
						top
					>
					<template v-slot:activator="{ on, attrs }">
						<v-btn text 
							v-if="!cedula" 
							@click="buscarCedula" 
							:disabled="loading_cedula"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left>mdi-magnify</v-icon>
							<v-icon left>mdi-file</v-icon>
							Buscar en cedulas
						</v-btn>
						<v-btn flat color="green" dark 
							:to="{name: 'cedula', params: {datetimeURL: datetimeURL}}"
							v-else
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left>mdi-file</v-icon>
							Ver la cedula
							<v-icon right>mdi-arrow-right</v-icon>
						</v-btn>
					</template>
					<span>{{cedula_tooltip}}</span>
					</v-tooltip>
				</div>
				<v-overlay absolute v-model="loading_cedula" opacity="100">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-overlay>
			</v-toolbar>
			<v-tabs v-model="tab">
				<v-tab>Datos personales</v-tab>
				<v-tab v-if="alumno['AUTORIZADO']">
					<v-badge :content="alumno['AUTORIZADO'].split(',').length" color="primary darken-1">Autorizados a retirar</v-badge>
				</v-tab>
				<v-tab v-else-if="alumno['HERMANOS']">
					<v-badge :content="'?'" color="secondary">Autorizados a retirar</v-badge>
				</v-tab>
				<v-tab v-if="alumno['HERMANOS']">
					<v-badge :content="alumno['HERMANOS'].split(',').length" color="primary darken-1">Hermanos</v-badge>
				</v-tab>
				<v-tab v-if="alumno['NOTAS']">Notas</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<DatosPersonales
						:alumno="alumno"
					></DatosPersonales>
				</v-tab-item>
				<v-tab-item v-if="alumno['AUTORIZADO'] || alumno['HERMANOS']">
					<DatosAutorizados
						:alumno="alumno"
						:alumnos="alumnos"
					></DatosAutorizados>
				</v-tab-item>
				<v-tab-item v-if="alumno['HERMANOS']">
					<DatosHermanos
						:alumno="alumno"
						:alumnos="alumnos"
					></DatosHermanos>
				</v-tab-item>
				<v-tab-item v-if="alumno['NOTAS']">
					<DatosNotas
						:alumno="alumno"
					></DatosNotas>
				</v-tab-item>
			</v-tabs-items>
			<v-overlay v-model="loading" absolute>
				<v-progress-circular indeterminate></v-progress-circular>
			</v-overlay>
		</v-card>
	</v-container>
</template>

<script>
import moment from "moment";
import AlumnosHelper from "@/utils/alumnos-helper";
import CedulasHelper from "@/utils/cedulas-helper";
import DatosPersonales from "@/components/alumno/datos-personales.vue";
import DatosAutorizados from "../components/alumno/datos-autorizados.vue";
import DatosHermanos from "@/components/alumno/datos-hermanos.vue";
import DatosNotas from "@/components/alumno/datos-notas.vue";

export default {
    props: {
        id: String,
    },
    data: () => ({
		loading: false,
		loading_cedula: false,
        tab: undefined,
        cursos: [],
        alumnos: [],
		cedula: null,
		cedula_tooltip_show: false,
		cedula_tooltip: "Buscar en el listado de Cedulas este Alumno",
		cedula_tooltip_color: ""
    }),
    methods: {
        async loadData() {
            this.loading = true;
            this.cursos = await AlumnosHelper.getCursos();
            this.alumnos = await AlumnosHelper.getAlumnos(this.cursos);
            this.loading = false;
        },
		async buscarCedula() {
			this.loading_cedula = true;
			this.cedula = null;

			let cedulas = await CedulasHelper.getCedulas();
			
			for(let i = 0; i < cedulas.length; i++) {
				let cedula = cedulas[i];
				let cedula_dni = cedula.datos_del_alumno.documento_numero;

				cedula_dni = cedula_dni.replaceAll('.', '').trim();

				if(cedula_dni == this.alumno["DNI"]) {
					this.cedula = cedula;
					break;
				}
			}

			if(this.cedula !== null) {
				this.cedula_tooltip_show = true;
				this.cedula_tooltip = "¡Se encontro la Cedula del Alumno!";
				this.cedula_tooltip_color = "success";
			} else {
				this.cedula_tooltip_show = true;
				this.cedula_tooltip = "No se encontró la Cedula";
				this.cedula_tooltip_color = "error";
			}

			this.loading_cedula = false;
		}
    },
    computed: {
        alumno() {
            let alumno = {};
            for (let i = 0; i < this.alumnos.length; i++) {
                if (this.id == this.alumnos[i]["ID"]) {
                    alumno = this.alumnos[i];
                    break;
                }
            }
            return alumno;
        },
		datetimeURL() {
			let datetimeformat = CedulasHelper.getDatetimeFormat();
			let datetimeURLformat = CedulasHelper.getDatetimeURLFormat();

			return moment(this.cedula.inscripcion.datetime, datetimeformat).format(datetimeURLformat);

		}
    },
    watch: {
        "id"() {
            this.tab = 0;
        }
    },
    async created() {
        await this.loadData();
    },
    components: { DatosAutorizados, DatosNotas, DatosHermanos, DatosPersonales }
}
</script>

<style>

</style>