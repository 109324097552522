<template>
	<v-app id="app">
		<AppBar></AppBar>
		<NavigationDrawer></NavigationDrawer>
		<v-main>
			<v-scroll-x-transition mode="out-in">
				<router-view/>
			</v-scroll-x-transition>
		</v-main>
		<LoginPanel></LoginPanel>
	</v-app>
</template>

<script>
import LoginPanel from "./components/login-panel.vue";
import NavigationDrawer from "./components/navigation-drawer.vue";
import AppBar from "./components/app-bar.vue";

export default {
    name: "App",
    data: () => ({
    //
    }),
    components: { LoginPanel, NavigationDrawer, AppBar }
};
</script>

<style>
#app {
    background-color: var(--v-background-base);
}
</style>
