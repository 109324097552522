<template>
    <v-card-text>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">ESTADO</v-sheet>
            <v-sheet class="text-body-1 pa-2">{{alumno["ESTADO"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">NACIMIENTO</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["NACIMIENTO"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">EDAD</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["EDAD"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">SEXO</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["SEXO"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">DNI</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["DNI"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">TELEFONOS</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["TELEFONOS"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">DIRECCION</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["DIRECCION"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">NACIONALIDAD</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["NACIONALIDAD"]}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">¿SALA DE 5?</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["SALA DE 5"] ? alumno["SALA DE 5"] : "NO"}}</v-sheet>
        </v-sheet>
        <v-sheet class="d-flex">
            <v-sheet class="text-body-1 py-2 primary--text">¿DISCAPACIDAD?</v-sheet>
            <v-sheet class="text-body-1 pa-2 border">{{alumno["DISCAPACIDAD"] ? alumno["DISCAPACIDAD"] : "NO"}}</v-sheet>
        </v-sheet>
    </v-card-text>
</template>

<script>
export default {
    props: {
        alumno: Object
    }
}
</script>

<style>

</style>