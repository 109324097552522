<template>
    <v-card-text>
        <v-row>
            <v-col class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(autorizado, index) in autorizados" :key="index">
                <v-card outlined>
                    <v-card-title>{{autorizado["PARENTESCO"]}}</v-card-title>
                    <v-card-text>
                        <v-sheet class="d-flex">
                            <v-sheet class="text-body-1 py-2 primary--text">NOMBRE</v-sheet>
                            <v-sheet class="text-body-1 pa-2 border">{{autorizado["NOMBRE"]}}</v-sheet>
                        </v-sheet>
                        <v-sheet class="d-flex">
                            <v-sheet class="text-body-1 py-2 primary--text">DNI</v-sheet>
                            <v-sheet class="text-body-1 pa-2 border" :color="!autorizado['DNI'] ? 'warning' : ''">
                                {{autorizado["DNI"]}}
                                <div v-if="!autorizado['DNI']">FALTA DNI</div>
                            </v-sheet>
                        </v-sheet>
                        <v-sheet class="d-flex">
                            <v-sheet class="text-body-1 py-2 primary--text">TELEFONO</v-sheet>
                            <v-sheet class="text-body-1 pa-2 border">{{autorizado["TELEFONO"]}}</v-sheet>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
export default {
    props: {
        alumno: Object,
        alumnos: Array
    },
    data: () => ({

    }),
    computed: {
        autorizados() {
            let autorizados = [];
            let alumno = this.alumno;
            const delimiters = ",";
            if (alumno["AUTORIZADO"] === undefined && this.hermanos.length > 0) {
                for (let i = 0; i < this.hermanos.length; i++) {
                    let hermano = this.hermanos[i];
                    if (hermano["AUTORIZADO"] !== undefined) {
                        alumno = hermano;
                        alumno["AUTORIZADO POR EL HERMANO"] = hermano;
                    }
                }
            }
            if (alumno["AUTORIZADO"] === undefined)
                return [];
            let autorizados_nombre = alumno["AUTORIZADO"].split(delimiters);
            let autorizados_dni = alumno["AUTORIZADO DNI"].split(delimiters);
            let autorizados_telefono = alumno["AUTORIZADO TELEFONO"].split(delimiters);
            let autorizados_parentesco = alumno["AUTORIZADO PARENTESCO"].split(delimiters);
            let autorizado_hermano = alumno["AUTORIZADO POR EL HERMANO"];
            // TODO check if autorizados length is the same everywhere
            for (let i = 0; i < autorizados_nombre.length; i++) {
                autorizados.push({
                    "NOMBRE": autorizados_nombre[i].trim(),
                    "DNI": autorizados_dni[i].trim(),
                    "TELEFONO": autorizados_telefono[i].trim(),
                    "PARENTESCO": autorizados_parentesco[i].trim(),
                    "POR HERMANO": autorizado_hermano
                });
            }
            return autorizados;
        },
        hermanos() {
            let hermanos = [];
            if (this.alumno["HERMANOS"] === undefined)
                return [];
            const delimiters = /[\s,]+/;
            let hermanosIDs = this.alumno["HERMANOS"].split(delimiters);
            for (let i = 0; i < this.alumnos.length; i++) {
                let hermano = this.alumnos[i];
                if (hermanosIDs.includes(hermano["ID"])) {
                    hermanos.push(hermano);
                }
            }
            return hermanos;
        }
    }
}
</script>

<style>

</style>