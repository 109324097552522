const API_URL = "https://api.escuela.conlatoso.com"

export default {
    /**
     * 
     * @param '{params.url, params.body, params.config}' params 
     * @returns json data
     * @throws NotOkResponseException
     */
    async fetch(params) {
        let config = params.config ? params.config : {};
        config.method = params.method;
        config.body = params.body === undefined ? undefined : JSON.stringify(params.body);
        config.headers = {
            'Content-Type': 'application/json'
        };
        let url = params.url.startsWith('/') ? API_URL + params.url : params.url;

        let response = await fetch(url, config);
        let data = await response.json();

        if (!response.ok) {
            data.type = 'HTTP';
            data.status = response.status;
            throw data;
        }

        return data;
    }
}