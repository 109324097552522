import InicioView from '@/views/inicio-view.vue'
import AlumnosView from '@/views/alumnos-view.vue'
import AlumnoSingleView from '@/views/alumno-single-view.vue'

import Vue from 'vue'
import VueRouter from 'vue-router'
import CedulasView from '@/views/cedulas-view.vue'
import CedulasSingleView from '@/views/cedulas-single-view.vue'

Vue.use(VueRouter)

const routes = [
	{
		name: 'inicio',
		path: '/',
		component: InicioView,
		meta: {
			icon: 'mdi-home',
			title: 'Inicio'
		}
	},
	{
		name: 'alumnos',
		path: '/alumnos',
		component: AlumnosView,
		meta: {
			icon: 'mdi-school',
			title: 'Alumnos'
		}
	},
	{
		name: 'alumno',
		path: '/alumnos/:id',
		component: AlumnoSingleView,
		props: true
	},
	{
		name: 'cedulas',
		path: '/cedulas',
		component: CedulasView,
		meta: {
			icon: 'mdi-file',
			title: 'Cedulas'
		}
	},
	{
		name: 'cedula',
		path: '/cedulas/:datetimeURL',
		component: CedulasSingleView,
		props: true
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
