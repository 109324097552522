<template>
    <v-container fluid>
        <v-card max-width="230mm" class="mx-auto cedula-card" style="overflow: auto">
            <v-toolbar flat>
                <v-btn icon @click="$router.back()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{cedula ? cedula.datos_del_alumno.apellidos+", "+cedula.datos_del_alumno.nombres : ""}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="print" class="mr-2">
                    Imprimir
                    <v-icon right>mdi-printer</v-icon>
                </v-btn>
                <v-btn text v-if="cedula && cedula.cargado"
                    color="green" dark
                    :to="{name: 'alumno', params: {id: cedula.cargado['ID']}}"
                >
                    <v-icon left>mdi-school</v-icon>
                    Ir a Alumno
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="cedula" v-if="cedula">
                <div class="cedula-hoja mx-auto">
                    <img class="cedula-svg" src="@/assets/cedulas/Cedula1.svg">
                    <div class="cedula-inputs">
                        <div class="cedula-inputs-div" name="cabecera">
                            <v-text-field
                                class="centered-input big-text-field"
                                style="top: 6.2mm; left: 137mm; width: 14mm; background: white"
                                dense
                                v-model="año_lectivo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input big-text-field"
                                style="top: 1mm; left: 190mm; width: 7mm; font-size: 1.2em"
                                dense
                                readonly
                                v-model="cedula.inscripcion.año"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input big-text-field"
                                style="top: 1mm; left: 195mm; width: 6mm; font-size: 1.2em"
                                dense
                                readonly
                                v-model="cedula.inscripcion.division"
                            ></v-text-field>
                        </div>
                        <div class="cedula-inputs-div" name="datos_establecimiento_inscribe">
                            <v-text-field
                                class="centered-input"
                                style="top: 33.2mm; left: 34mm; width: 24mm"
                                dense
                                v-model="datos_establecimiento_inscribe.clave_provincial"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 33.2mm; left: 70mm; width: 22.5mm"
                                dense
                                v-model="datos_establecimiento_inscribe.cue"
                            ></v-text-field>
                            <v-text-field
                                style="top: 38.4mm; left: 23mm; width: 86.8mm"
                                dense
                                v-model="datos_establecimiento_inscribe.nombre"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 38.4mm; left: 118mm; width: 14.4mm"
                                dense
                                v-model="datos_establecimiento_inscribe.numero"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 38.4mm; left: 149mm; width: 50mm"
                                dense
                                v-model="datos_establecimiento_inscribe.distrito"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 41mm; left: 44.6mm;"
                                v-model="datos_establecimiento_inscribe.sector"
                                value="Estatal"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 41.4mm; left: 72.2mm;"
                                v-model="datos_establecimiento_inscribe.sector"
                                value="Privado"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 41.4mm; left: 101.4mm;"
                                v-model="datos_establecimiento_inscribe.sector"
                                value="Municipal"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 41.4mm; left: 136.6mm;"
                                v-model="datos_establecimiento_inscribe.sector"
                                value="Nacional"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 41mm; left: 167.4mm;"
                                v-model="datos_establecimiento_inscribe.sector"
                                value="Otro"
                                hide-details
                            ></v-checkbox>
                        </div>
                        <div class="cedula-inputs-div" name="datos_establecimiento_procedencia">
                            <v-text-field
                                class="centered-input"
                                style="top: 64.8mm; left: 34.2mm; width: 24mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.clave_provincial"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 64.8mm; left: 70.2mm; width: 22.5mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.cue"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 64.8mm; left: 176.5mm; width: 23mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.modalidad"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 71mm; left: 23.5mm; width: 69.5mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.nombre"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 71.2mm; left: 103mm; width: 14mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.numero"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 71mm; left: 137.5mm; width: 62mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.direccion"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 77.2mm; left: 25.5mm; width: 30mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.localidad"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 77mm; left: 72mm; width: 30mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.distrito"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 77mm; left: 121.5mm; width: 30.4mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.provincia"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 77.2mm; left: 162.5mm; width: 37mm"
                                dense
                                v-model="cedula.establecimiento_de_procedencia.pais"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 80.2mm; left: 44.4mm; width: 37mm;"
                                v-model="cedula.establecimiento_de_procedencia.sector_gestion"
                                value="ESTATAL"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 80.4mm; left: 71.8mm; width: 37mm;"
                                v-model="cedula.establecimiento_de_procedencia.sector_gestion"
                                value="PRIVADO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 80.4mm; left: 101mm; width: 37mm;"
                                v-model="cedula.establecimiento_de_procedencia.sector_gestion"
                                value="MUNICIPAL"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 80.4mm; left: 136.2mm; width: 37mm;"
                                v-model="cedula.establecimiento_de_procedencia.sector_gestion"
                                value="NACIONAL"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 80.2mm; left: 167.2mm; width: 37mm;"
                                v-model="cedula.establecimiento_de_procedencia.sector_gestion"
                                value="OTRO"
                                hide-details
                            ></v-checkbox>
                        </div>
                        <div class="cedula-inputs-div" name="inscripcion">
                            <v-checkbox
                                color="black"
                                style="top: 98.3mm; left: 58.2mm;"
                                v-model="cedula.inscripcion.año"
                                value="1°"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 98.3mm; left: 70.5mm;"
                                v-model="cedula.inscripcion.año"
                                value="2°"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 98.3mm; left: 82.6mm;"
                                v-model="cedula.inscripcion.año"
                                value="3°"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 98.4mm; left: 94.9mm;"
                                v-model="cedula.inscripcion.año"
                                value="4°"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 98.4mm; left: 107mm;"
                                v-model="cedula.inscripcion.año"
                                value="5°"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 98.4mm; left: 118.6mm;"
                                v-model="cedula.inscripcion.año"
                                value="6°"
                                hide-details
                            ></v-checkbox>

                            <v-tooltip top color="black">
                                <template v-slot:activator="{on,attrs}">
                                    <div class="division-checkboxes" style="
                                        position: absolute; 
                                        padding: 2px; 
                                        background: #fccc; 
                                        top: 103mm; 
                                        left: 130mm;
                                        transform: scale(0.8);
                                        display: flex"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-checkbox
                                            color="black"
                                            class="mt-0 pt-0 mr-1"
                                            style="position: relative;"
                                            v-model="cedula.inscripcion.division"
                                            value="A"
                                            label="A"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            color="black"
                                            class="mt-0 pt-0 mr-1"
                                            style="position: relative;"
                                            v-model="cedula.inscripcion.division"
                                            value="B"
                                            label="B"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            color="black"
                                            class="mt-0 pt-0 mr-1"
                                            style="position: relative;"
                                            v-model="cedula.inscripcion.division"
                                            value="C"
                                            label="C"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            color="black"
                                            class="mt-0 pt-0 mr-1"
                                            style="position: relative;"
                                            v-model="cedula.inscripcion.division"
                                            value="D"
                                            label="D"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            color="black"
                                            class="mt-0 pt-0 mr-1"
                                            style="position: relative;"
                                            v-model="cedula.inscripcion.division"
                                            value="E"
                                            label="E"
                                            hide-details
                                        ></v-checkbox>
                                    </div>
                                </template>
                                <span>La división no se imprime</span>
                            </v-tooltip>

                            <v-checkbox
                                color="black"
                                style="top: 105.8mm; left: 42.2mm;"
                                v-model="cedula.inscripcion.turno"
                                value="Mañana"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 105.8mm; left: 62.8mm;"
                                v-model="cedula.inscripcion.turno"
                                value="Tarde"
                                hide-details
                            ></v-checkbox>

                            <v-checkbox
                                color="black"
                                style="top: 114mm; left: 52.8mm;"
                                v-model="cedula.inscripcion.estado"
                                value="INGRESANTE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 119.6mm; left: 52.8mm;"
                                v-model="cedula.inscripcion.estado"
                                value="REINSCRIPTO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 125mm; left: 52.8mm;"
                                v-model="cedula.inscripcion.estado"
                                value="PROMOVIDO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 128.5mm; left: 102.4mm;"
                                v-model="cedula.inscripcion.estado"
                                value="REPITENTE"
                                hide-details
                            ></v-checkbox>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 136mm; left: 140.8mm"
                                v-model="cedula.trayectoria.asistio_jardin"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0.2mm; left: 13.6mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 142.8mm; left: 141mm"
                                v-model="cedula.trayectoria.asistio_institucion"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0.2mm; left: 13.6mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="cedula-inputs-div" name="datos_del_alumno">
                            <v-text-field
                                class="centered-input"
                                style="top: 166.2mm; left: 30mm; width: 12mm"
                                dense
                                v-model="cedula.datos_del_alumno.documento_tipo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 166.2mm; left: 48.5mm; width: 29mm"
                                dense
                                v-model="cedula.datos_del_alumno.documento_numero"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 163.2mm; left: 80.2mm;"
                                v-model="cedula.datos_del_alumno.documento_posee"
                                value="POSEE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 163.2mm; left: 98mm;"
                                v-model="cedula.datos_del_alumno.documento_posee"
                                value="EN TRÁMITE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 162.8mm; left: 122.2mm;"
                                v-model="cedula.datos_del_alumno.documento_posee"
                                value="NO POSEE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 162.7mm; left: 168.2mm;"
                                v-model="cedula.datos_del_alumno.documento_estado"
                                value="BUENO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 162.7mm; left: 186.1mm;"
                                v-model="cedula.datos_del_alumno.documento_estado"
                                value="MALO"
                                hide-details
                            ></v-checkbox>
                            <v-text-field
                                style="top: 172.5mm; left: 27mm; width: 58mm"
                                dense
                                v-model="cedula.datos_del_alumno.apellidos"
                            ></v-text-field>
                            <v-text-field
                                style="top: 172.5mm; left: 105mm; width: 94mm"
                                dense
                                v-model="cedula.datos_del_alumno.nombres"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 179mm; left: 19.5mm; width: 12mm"
                                dense
                                v-model="cedula.datos_del_alumno.sexo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 179mm; left: 57.5mm; width: 25mm"
                                dense
                                v-model="cedula.datos_del_alumno.fecha_de_nacimiento"
                            ></v-text-field>
                            <v-text-field
                                style="top: 179mm; left: 107mm; width: 40mm"
                                dense
                                v-model="cedula.datos_del_alumno.lugar_de_nacimiento"
                            ></v-text-field>
                            <v-text-field
                                style="top: 179mm; left: 170mm; width: 29mm"
                                dense
                                v-model="cedula.datos_del_alumno.nacionalidad"
                            ></v-text-field>
                            <v-text-field
                                style="top: 185.8mm; left: 39.5mm; width: 91mm"
                                dense
                                v-model="cedula.datos_del_alumno.calle"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 185.9mm; left: 137.5mm; width: 11mm; font-size: 0.75em"
                                dense
                                v-model="cedula.datos_del_alumno.calle_numero"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 185.9mm; left: 160.5mm; width: 11mm; font-size: 0.75em"
                                dense
                                v-model="cedula.datos_del_alumno.piso"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 186mm; left: 183mm; width: 16mm; font-size: 0.75em"
                                dense
                                v-model="cedula.datos_del_alumno.torre"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 192.5mm; left: 19mm; width: 11mm; font-size: 0.75em"
                                dense
                                v-model="cedula.datos_del_alumno.departamento"
                            ></v-text-field>
                            <v-text-field
                                style="top: 192mm; left: 52.5mm; width: 147mm;"
                                dense
                                v-model="cedula.datos_del_alumno.entre_calles"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 206mm; left: 25mm; width: 31mm;"
                                dense
                                v-model="cedula.datos_del_alumno.provincia"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 206mm; left: 72.8mm; width: 31mm;"
                                dense
                                v-model="cedula.datos_del_alumno.distrito"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 206mm; left: 122.8mm; width: 30mm;"
                                dense
                                v-model="cedula.datos_del_alumno.localidad"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 206.3mm; left: 178.5mm; width: 21mm;"
                                dense
                                v-model="helpers.datos_del_alumno.codigo_postal"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 212.4mm; left: 25mm; width: 31.5mm;"
                                dense
                                v-model="cedula.datos_del_alumno.telefono"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 212.2mm; left: 87mm; width: 37mm;"
                                dense
                                v-model="cedula.datos_del_alumno.celular"
                            ></v-text-field>
                        </div>
                        <div class="cedula-inputs-div" name="datos_del_alumno">
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 231.2mm; left: 25.85mm"
                                v-model="helpers.datos_del_alumno.posee_hermanos"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 11.5mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                class="centered-input"
                                style="top: 234.2mm; left: 66mm; width: 11mm"
                                dense
                                v-model="cedula.datos_del_alumno.cantidad_hermanos"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 234.2mm; left: 186.5mm; width: 13mm"
                                dense
                                v-model="cedula.datos_del_alumno.cantidad_hermanos_establecimiento"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 242mm; left: 60.7mm; width: 11mm"
                                dense
                                v-model="cedula.datos_del_alumno.cantidad_habitantes"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 242mm; left: 128.4mm; width: 11mm"
                                dense
                                v-model="cedula.datos_del_alumno.cantidad_habitaciones"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 242mm; left: 188.6mm; width: 11mm"
                                dense
                                v-model="cedula.datos_del_alumno.otra_lengua"
                            ></v-text-field>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 245.8mm; left: 41.8mm"
                                v-model="cedula.datos_del_alumno.ayuda_escolar"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0.1mm; left: 11.6mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 245.6mm; left: 92.5mm"
                                v-model="cedula.datos_del_alumno.obra_social"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0.2mm; left: 11.5mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 252.5mm; left: 87.8mm"
                                v-model="helpers.datos_del_alumno.algun_plan"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 11.6mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-checkbox
                                color="black"
                                style="top: 252mm; left: 118.5mm;"
                                v-model="helpers.datos_del_alumno.plan"
                                value="AUH"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 251.4mm; left: 162.8mm;"
                                v-model="helpers.datos_del_alumno.plan"
                                value="BECAS POR EXCEPCION"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 257.6mm; left: 118.5mm;"
                                v-model="helpers.datos_del_alumno.plan"
                                value="PROGRESAR"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 257.1mm; left: 162.8mm;"
                                v-model="helpers.datos_del_alumno.plan"
                                value="OTROS"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 262.8mm; left: 118.5mm;"
                                v-model="helpers.datos_del_alumno.plan"
                                value="BECAS PARA JUDICIALIZADOS"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 275.2mm; left: 10.1mm;"
                                v-model="helpers.datos_del_alumno.medio_de_transporte"
                                value="A PIE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 275.2mm; left: 44.6mm;"
                                v-model="helpers.datos_del_alumno.medio_de_transporte"
                                value="OMNIBUS"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 275.3mm; left: 89.5mm;"
                                v-model="helpers.datos_del_alumno.medio_de_transporte"
                                value="AUTO PARTICULAR"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 275mm; left: 131.4mm;"
                                v-model="helpers.datos_del_alumno.medio_de_transporte"
                                value="TAXI/REMIS"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 275mm; left: 167mm;"
                                v-model="helpers.datos_del_alumno.medio_de_transporte"
                                value="OTRO"
                                hide-details
                            ></v-checkbox>
                        </div>
                    </div>
                </div>
                <div class="cedula-hoja mx-auto">
                    <img class="cedula-svg" src="@/assets/cedulas/Cedula2.svg">
                    <div class="cedula-hoja-2 cedula-inputs">
                        <div class="cedula-inputs-div small" name="datos_de_la_madre">
                            <v-text-field
                                style="top: 14.8mm; left: 24mm; width: 64.4mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.apellidos"
                            ></v-text-field>
                            <v-text-field
                                style="top: 14.8mm; left: 107mm; width: 90mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.nombres"
                            ></v-text-field>
                            <v-text-field
                                style="top: 19.2mm; left: 27.6mm; width: 67.5mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.nacionalidad"
                            ></v-text-field>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 15.1mm; left: 174mm"
                                v-model="helpers.datos_de_la_madre.alguna_educacion"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 46.4mm;"
                                v-model="cedula.datos_de_la_madre.educacion"
                                value="PRIMARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 68.2mm;"
                                v-model="cedula.datos_de_la_madre.educacion"
                                value="SECUNDARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 94.2mm;"
                                v-model="cedula.datos_de_la_madre.educacion"
                                value="TERCIARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 117.2mm;"
                                v-model="cedula.datos_de_la_madre.educacion"
                                value="UNIVERSITARIO"
                                hide-details
                            ></v-checkbox>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 20mm; left: 173.7mm"
                                v-model="cedula.datos_de_la_madre.educacion_completo"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 25mm; left: 19.6mm"
                                v-model="cedula.datos_de_la_madre.vive"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 14.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                class="centered-input"
                                style="top: 36mm; left: 26.4mm; width: 13.6mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.documento_tipo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 36mm; left: 46.6mm; width: 33.4mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.documento_numero"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 31.2mm; left: 138.5mm;"
                                v-model="cedula.datos_de_la_madre.documento_posee"
                                value="EN TRÁMITE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 31.3mm; left: 167.8mm;"
                                v-model="cedula.datos_de_la_madre.documento_posee"
                                value="NO POSEE"
                                hide-details
                            ></v-checkbox>
                            <v-text-field
                                style="top: 41.7mm; left: 36mm; width: 93mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.calle"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 41.7mm; left: 135.4mm; width: 11mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.calle_numero"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 41.6mm; left: 157.2mm; width: 11mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.piso"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 41.6mm; left: 180.2mm; width: 16.2mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.torre"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.5mm; left: 18mm; width: 9.4mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.departamento"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 43mm; width: 30.6mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.provincia"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 89mm; width: 29mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.distrito"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 136mm; width: 29mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.localidad"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 185mm; width: 11.4mm;"
                                dense
                                v-model="helpers.datos_de_la_madre.codigo_postal"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 53.8mm; left: 22.5mm; width: 32mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.telefono"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 53.8mm; left: 82mm; width: 39.6mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.celular"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 53.8mm; left: 138.4mm; width: 58.8mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.email"
                            ></v-text-field>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 55.6mm; left: 35.2mm"
                                v-model="cedula.datos_de_la_madre.jefe_de_hogar"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                style="top: 59.7mm; left: 100.4mm; width: 96.4mm;"
                                dense
                                v-model="cedula.datos_de_la_madre.profesion"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 66.8mm; left: 7.4mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="SÓLO TRABAJA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.6mm; left: 38.2mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="TRABAJA Y ESTUDIA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.6mm; left: 75.2mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="TRABAJA Y RECIBE JUBILACIÓN O PENSIÓN"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.6mm; left: 133.4mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="SÓLO BUSCA TRABAJO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.4mm; left: 171mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="SÓLO ESTUDIA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 7.4mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="BUSCA TRABAJO Y ESTUDIA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 57.6mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="BUSCA TRABAJO Y RECIBE JUBILACIÓN O PENSIÓN"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 125.4mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="JUBILADO/PENSIONADO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 171mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="OTRO"
                                hide-details
                            ></v-checkbox>
                        </div>
                        <div class="cedula-inputs-div small" style="top: 78.6mm" name="datos_del_padre">
                            <v-text-field
                                style="top: 14.8mm; left: 24mm; width: 64.4mm;"
                                dense
                                v-model="cedula.datos_del_padre.apellidos"
                            ></v-text-field>
                            <v-text-field
                                style="top: 14.8mm; left: 107mm; width: 90mm;"
                                dense
                                v-model="cedula.datos_del_padre.nombres"
                            ></v-text-field>
                            <v-text-field
                                style="top: 19.2mm; left: 27.6mm; width: 67.5mm;"
                                dense
                                v-model="cedula.datos_del_padre.nacionalidad"
                            ></v-text-field>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 15.1mm; left: 174mm"
                                v-model="helpers.datos_del_padre.alguna_educacion"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 46.4mm;"
                                v-model="cedula.datos_del_padre.educacion"
                                value="PRIMARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 68.2mm;"
                                v-model="cedula.datos_del_padre.educacion"
                                value="SECUNDARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 94.2mm;"
                                v-model="cedula.datos_del_padre.educacion"
                                value="TERCIARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 117.2mm;"
                                v-model="cedula.datos_del_padre.educacion"
                                value="UNIVERSITARIO"
                                hide-details
                            ></v-checkbox>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 20mm; left: 173.7mm"
                                v-model="cedula.datos_del_padre.educacion_completo"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 25mm; left: 19.6mm"
                                v-model="cedula.datos_del_padre.vive"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 14.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                class="centered-input"
                                style="top: 36mm; left: 26.4mm; width: 13.6mm;"
                                dense
                                v-model="cedula.datos_del_padre.documento_tipo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 36mm; left: 46.6mm; width: 33.4mm;"
                                dense
                                v-model="cedula.datos_del_padre.documento_numero"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 31.2mm; left: 138.5mm;"
                                v-model="cedula.datos_del_padre.documento_posee"
                                value="EN TRÁMITE"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 31.3mm; left: 167.8mm;"
                                v-model="cedula.datos_del_padre.documento_posee"
                                value="NO POSEE"
                                hide-details
                            ></v-checkbox>
                            <v-text-field
                                style="top: 41.7mm; left: 36mm; width: 93mm;"
                                dense
                                v-model="cedula.datos_del_padre.calle"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 41.7mm; left: 135.4mm; width: 11mm;"
                                dense
                                v-model="cedula.datos_del_padre.calle_numero"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 41.6mm; left: 157.2mm; width: 11mm;"
                                dense
                                v-model="cedula.datos_del_padre.piso"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 41.6mm; left: 180.2mm; width: 16.2mm;"
                                dense
                                v-model="cedula.datos_del_padre.torre"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.5mm; left: 18mm; width: 9.4mm;"
                                dense
                                v-model="cedula.datos_del_padre.departamento"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 43mm; width: 30.6mm;"
                                dense
                                v-model="cedula.datos_del_padre.provincia"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 89mm; width: 29mm;"
                                dense
                                v-model="cedula.datos_del_padre.distrito"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 136mm; width: 29mm;"
                                dense
                                v-model="cedula.datos_del_padre.localidad"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 47.4mm; left: 185mm; width: 11.4mm;"
                                dense
                                v-model="helpers.datos_del_padre.codigo_postal"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 53.8mm; left: 22.5mm; width: 32mm;"
                                dense
                                v-model="cedula.datos_del_padre.telefono"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 53.8mm; left: 82mm; width: 39.6mm;"
                                dense
                                v-model="cedula.datos_del_padre.celular"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 53.8mm; left: 138.4mm; width: 58.8mm;"
                                dense
                                v-model="cedula.datos_del_padre.email"
                            ></v-text-field>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 55.6mm; left: 35.2mm"
                                v-model="cedula.datos_del_padre.jefe_de_hogar"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field
                                style="top: 59.7mm; left: 100.4mm; width: 96.4mm;"
                                dense
                                v-model="cedula.datos_del_padre.profesion"
                            ></v-text-field>
                            <v-checkbox
                                color="black"
                                style="top: 66.8mm; left: 7.4mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="SÓLO TRABAJA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.6mm; left: 38.2mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="TRABAJA Y ESTUDIA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.6mm; left: 75.2mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="TRABAJA Y RECIBE JUBILACIÓN O PENSIÓN"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.6mm; left: 133.4mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="SÓLO BUSCA TRABAJO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 66.4mm; left: 171mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="SÓLO ESTUDIA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 7.4mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="BUSCA TRABAJO Y ESTUDIA"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 57.6mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="BUSCA TRABAJO Y RECIBE JUBILACIÓN O PENSIÓN"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 125.4mm;"
                                v-model="cedula.datos_del_padre.condicion"
                                value="JUBILADO/PENSIONADO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 71.8mm; left: 171mm;"
                                v-model="cedula.datos_de_la_madre.condicion"
                                value="OTRO"
                                hide-details
                            ></v-checkbox>
                        </div>
                        <div class="cedula-inputs-div small" style="top: 157.2mm; left: 0.2mm" name="datos_del_tutor">
                            <v-text-field
                                style="top: 14.8mm; left: 24mm; width: 64.4mm;"
                                dense
                                v-model="cedula.datos_del_tutor.apellidos"
                            ></v-text-field>
                            <v-text-field
                                style="top: 14.8mm; left: 107mm; width: 90mm;"
                                dense
                                v-model="cedula.datos_del_tutor.nombres"
                            ></v-text-field>
                            <v-text-field
                                style="top: 19.2mm; left: 27.6mm; width: 67.5mm;"
                                dense
                                v-model="cedula.datos_del_tutor.nacionalidad"
                            ></v-text-field>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 15.1mm; left: 174mm"
                                v-model="helpers.datos_del_tutor.alguna_educacion"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 46.4mm;"
                                v-model="cedula.datos_del_tutor.educacion"
                                value="PRIMARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 68.2mm;"
                                v-model="cedula.datos_del_tutor.educacion"
                                value="SECUNDARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 94.2mm;"
                                v-model="cedula.datos_del_tutor.educacion"
                                value="TERCIARIO"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                color="black"
                                style="top: 19.8mm; left: 117.2mm;"
                                v-model="cedula.datos_del_tutor.educacion"
                                value="UNIVERSITARIO"
                                hide-details
                            ></v-checkbox>
                            <v-radio-group
                                class="radio-group"
                                dense
                                style="top: 20mm; left: 173.7mm"
                                v-model="cedula.datos_del_tutor.educacion_completo"
                            >
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    value="SI"
                                ></v-radio>
                                <v-radio
                                    color="black"
                                    class="radio-v"
                                    style="top: 0mm; left: 13.2mm"
                                    value="NO"
                                ></v-radio>
                            </v-radio-group>
                            <div style="position: relative; top: -6.4mm; left: 0mm">
                                <v-text-field
                                    class="centered-input"
                                    style="top: 36mm; left: 26.4mm; width: 13.6mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.documento_tipo"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 36mm; left: 46.6mm; width: 33.4mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.documento_numero"
                                ></v-text-field>
                                <v-checkbox
                                    color="black"
                                    style="top: 31.2mm; left: 138.5mm;"
                                    v-model="cedula.datos_del_tutor.documento_posee"
                                    value="En trámite"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 31.3mm; left: 167.8mm;"
                                    v-model="cedula.datos_del_tutor.documento_posee"
                                    value="No posee"
                                    hide-details
                                ></v-checkbox>
                                <v-text-field
                                    style="top: 41.7mm; left: 36mm; width: 93mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.calle"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 41.7mm; left: 135.4mm; width: 11mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.calle_numero"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 41.6mm; left: 157.2mm; width: 11mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.piso"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 41.6mm; left: 180.2mm; width: 16.2mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.torre"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 47.5mm; left: 18mm; width: 9.4mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.departamento"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 47.4mm; left: 43mm; width: 30.6mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.provincia"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 47.4mm; left: 89mm; width: 29mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.distrito"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 47.4mm; left: 136mm; width: 29mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.localidad"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 47.4mm; left: 185mm; width: 11.4mm;"
                                    dense
                                    v-model="helpers.datos_del_tutor.codigo_postal"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 54.2mm; left: 22.5mm; width: 32mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.telefono"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 54.2mm; left: 82mm; width: 39.6mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.celular"
                                ></v-text-field>
                                <v-text-field
                                    class="centered-input"
                                    style="top: 54.2mm; left: 138.4mm; width: 58.8mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.email"
                                ></v-text-field>
                                <v-radio-group
                                    class="radio-group"
                                    dense
                                    style="top: 55.8mm; left: 35.2mm"
                                    v-model="cedula.datos_del_tutor.jefe_de_hogar"
                                >
                                    <v-radio
                                        color="black"
                                        class="radio-v"
                                        value="SI"
                                    ></v-radio>
                                    <v-radio
                                        color="black"
                                        class="radio-v"
                                        style="top: 0mm; left: 13.2mm"
                                        value="NO"
                                    ></v-radio>
                                </v-radio-group>
                                <v-text-field
                                    style="top: 59.9mm; left: 100.4mm; width: 96.4mm;"
                                    dense
                                    v-model="cedula.datos_del_tutor.profesion"
                                ></v-text-field>
                                <v-checkbox
                                    color="black"
                                    style="top: 67.2mm; left: 7.6mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="SÓLO TRABAJA"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 66.8mm; left: 38.2mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="TRABAJA Y ESTUDIA"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 66.6mm; left: 75.2mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="TRABAJA Y RECIBE JUBILACIÓN O PENSIÓN"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 66.8mm; left: 133.4mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="SÓLO BUSCA TRABAJO"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 66.6mm; left: 170.8mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="SÓLO ESTUDIA"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 71.6mm; left: 7.6mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="BUSCA TRABAJO Y ESTUDIA"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 72mm; left: 57.6mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="BUSCA TRABAJO Y RECIBE JUBILACIÓN O PENSIÓN"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 71.8mm; left: 125.4mm;"
                                    v-model="cedula.datos_del_tutor.condicion"
                                    value="JUBILADO/PENSIONADO"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    color="black"
                                    style="top: 71.8mm; left: 171mm;"
                                    v-model="cedula.datos_de_la_madre.condicion"
                                    value="OTRO"
                                    hide-details
                                ></v-checkbox>
                            </div>
                        </div>
                        <div class="cedula-inputs-div small" name="autorizado_a_retirar">
                            <v-text-field
                                style="top: 243.2mm; left: 24.2mm; width: 67mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar1.apellidos"
                            ></v-text-field>
                            <v-text-field
                                style="top: 243.3mm; left: 108mm; width: 89mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar1.nombres"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 248.5mm; left: 27mm; width: 13mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar1.documento_tipo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 248.5mm; left: 47mm; width: 33mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar1.documento_numero"
                            ></v-text-field>
                            <v-text-field
                                style="top: 248.6mm; left: 130.4mm; width: 66.8mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar1.vinculo"
                            ></v-text-field>
                            <v-text-field
                                style="top: 254.5mm; left: 24.2mm; width: 67mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar2.apellidos"
                            ></v-text-field>
                            <v-text-field
                                style="top: 254.5mm; left: 108mm; width: 89mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar2.nombres"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 259.8mm; left: 27mm; width: 13mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar2.documento_tipo"
                            ></v-text-field>
                            <v-text-field
                                class="centered-input"
                                style="top: 259.8mm; left: 47mm; width: 33mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar2.documento_numero"
                            ></v-text-field>
                            <v-text-field
                                style="top: 259.8mm; left: 130.4mm; width: 66.8mm;"
                                dense
                                v-model="cedula.autorizado_a_retirar2.vinculo"
                            ></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="cedula-hoja mx-auto">
                    <img class="cedula-svg" src="@/assets/cedulas/Cedula3.svg">
                    <div class="cedula-hoja-3 cedula-inputs">

                        <div class="cedula-inputs-div small" name="datos_del_alumno">
                        <v-text-field
                            class="centered-input"
                            style="top: 18mm; left: 153.5mm; width: 12mm"
                            dense
                            v-model="cedula.datos_del_alumno.documento_tipo"
                        ></v-text-field>
                        <v-text-field
                            class="centered-input"
                            style="top: 18mm; left: 172mm; width: 29.5mm"
                            dense
                            v-model="cedula.datos_del_alumno.documento_numero"
                        ></v-text-field>
                        </div>
                    </div>
                </div>
            </div>
            <v-overlay v-model="loading" absolute>
                <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-container>
</template>

<script>
import Moment from 'moment'
import CedulasHelper from '@/utils/cedulas-helper'
import AlumnosHelper from '@/utils/alumnos-helper'

export default {
    props: {
        datetimeURL: String
    },
    data: () => ({
        loading: false,
        cedulas: [],
        cedula: null,
        año_lectivo: "2022",
        datos_establecimiento_inscribe: {
            clave_provincial: "0004PP0023",
            cue: "060728900",
            nombre: "Ricardo Güiraldes",
            numero: "23",
            distrito: "Almirante Brown",
            sector: "Estatal"
        },
        // variables que ayudan con campos que no son solo strings
        // por ejemplo plan o programa es un array de posibles valores
        // tiene hermanos si, no, tiene que ver con la cantidad de hermanos
        helpers: {
            datos_del_alumno: {
                plan: [],
                medio_de_transporte: [],
                posee_hermanos: "",
                algun_plan: "",
                codigo_postal: ""
            },
            datos_de_la_madre: {
                alguna_educacion: "",
                codigo_postal: ""
            },
            datos_del_padre: {
                alguna_educacion: "",
                codigo_postal: ""
            },
            datos_del_tutor: {
                alguna_educacion: "",
                codigo_postal: ""
            }
        }
    }),
    watch: {
        'helpers.datos_del_alumno.plan'(plan) {
            this.cedula.datos_del_alumno.plan = plan.toString();
        },
        'helpers.datos_del_alumno.medio_de_transporte'(medio_de_transporte) {
            this.cedula.datos_del_alumno.medio_de_transporte = medio_de_transporte.toString();
        }
    },
    methods: {
        async loadData() {
            this.cursos = await AlumnosHelper.getCursos();
            this.alumnos = await AlumnosHelper.getAlumnos(this.cursos);
            this.cedulas = await CedulasHelper.getCedulas();

            for(let i = 0; i < this.cedulas.length; i++) {
                let cedula = this.cedulas[i];
                if(cedula.inscripcion.datetime == this.datetime) {
                    this.cedula = cedula;
                    break;
                }
            }

            let alumno = this.findAlumno(this.cedula.datos_del_alumno.documento_numero);
            this.cedula.cargado = alumno;
        },
        loadHelpers() {
            if(this.cedula === null)
                return;

            let helper_alumno = this.helpers.datos_del_alumno;
            let helper_madre = this.helpers.datos_de_la_madre;
            let helper_padre = this.helpers.datos_del_padre;
            let helper_tutor = this.helpers.datos_del_tutor;
            let alumno = this.cedula.datos_del_alumno;
            let madre = this.cedula.datos_de_la_madre;
            let padre = this.cedula.datos_del_padre;
            let tutor = this.cedula.datos_del_tutor;

            helper_alumno.plan = this.splitCommaTrim(alumno.plan);
            helper_alumno.medio_de_transporte = this.splitCommaTrim(alumno.medio_de_transporte);
            helper_alumno.posee_hermanos = alumno.cantidad_hermanos ? "SI" : "NO";
            helper_alumno.algun_plan = helper_alumno.plan.length ? "SI" : "NO";

            helper_madre.alguna_educacion = madre.educacion ? "SI" : "NO";
            helper_padre.alguna_educacion = padre.educacion ? "SI" : "NO";
            helper_tutor.alguna_educacion = tutor.educacion ? "SI" : "NO";
        },
        splitCommaTrim(string) {
            return string.split(",").map(el => el.trim());
        },
        print() {
            window.print();
        },
        findAlumno(dni) {
            dni = dni.replaceAll('.', '');
            for(let i = 0; i < this.alumnos.length; i++) {
                let alumno = this.alumnos[i];

                if(alumno["DNI"] == dni) {
                    return alumno;
                }
            }
            return null;
        },
    },
    computed: {
        datetimeformat() {return CedulasHelper.getDatetimeFormat()},
        datetimeURLformat() {return CedulasHelper.getDatetimeURLFormat()},
        datetime() {return Moment(this.datetimeURL, this.datetimeURLformat)
            .format(this.datetimeformat)}
    },
    async created() {
        this.loading = true;
        await this.loadData();
        this.loadHelpers();
        this.loading = false;
    }
}
</script>

<style scoped>
.cedula {
    width: 100%;
}
.cedula-hoja {
    position: relative;
    width: 210mm;
    height: 297mm;
    outline: 1px solid;
    margin-bottom: 10mm;
}
.cedula-svg {
    width: 210mm;
    height: 297mm;
}
.cedula-inputs {
    width: 210mm;
    height: 297mm;
    position: absolute;
    top: 0;
    left: 0;
}
.cedula-inputs-div {
    width: 210mm;
    position: absolute;
    top: 0;
    left: 0;
}
.cedula-inputs-div .v-text-field {
    position: absolute;
    font-weight: bold;
    font-size: 0.9em;
}
.cedula-inputs-div .v-text-field >>> input {
    padding: 0px !important;
    padding-left: 1mm !important;
    padding-right: 1mm !important;
    margin-top: 1.2mm !important;
    height: 4mm !important;
}
.cedula-inputs-div.small .v-text-field >>> input {
    font-size: 0.8em;
    margin-top: 0mm !important;
}
.cedula-inputs-div .big-text-field >>> input {
    height: auto !important;
}
.cedula-inputs-div.small .radio-group {
    transform: scale(0.8);
}
.cedula-inputs-div.small .v-input--checkbox {
    transform: scale(0.6);
}
.centered-input >>> input {
    text-align: center;
}
.v-input--checkbox {
    position: absolute;
}
.radio-group {
    position: absolute;
}
.radio {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 4mm;
    height: 4mm;
    cursor: pointer;
}
.radio-v {
    position: absolute;
}
.radio > input {
    display: none;
}
.radio > .v-icon {
    position: absolute;
}
.radio > input + .v-icon {
    opacity: 0;
}
.radio > input:checked + .v-icon {
    opacity: 1;
}
</style>

<style>
@media print {
    .no-print {
        display: none !important;
    }
    .v-toolbar {
        display: none !important;
    }
    .v-navigation-drawer {
        display: none !important;
    }
    .v-main {
        padding: 0 !important;
    }
    .v-card, .container {
        padding: 0 !important;
        margin: 0 !important;
    }
    .v-input__slot::before {
        display: none !important;
    }
    .v-input__slot::after {
        display: none !important;
    }
    .mdi-checkbox-blank-outline {
        display: none !important;
    }
    .cedula-hora {
        outline: 0px;
    }
    .cedula-card {
        overflow: initial !important;
        box-shadow: initial !important;
    }
    .cedula {
        padding: 0px !important;
    }
    .elevation-14 {
        box-shadow: initial !important;
    }
    .division-checkboxes {
        display: none !important;
    }
}
</style>