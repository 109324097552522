import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
        token: "",
        showNavigation: null
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setNavigation(state, navigation) {
            state.showNavigation = navigation;
        }
    },
    getters: {
        getParsedToken: state => {
            return parseJwt(state.token);
		},
		getToken: state => {
			return state.token;
		}
    },
	actions: {
	},
	modules: {
	},
	plugins: [vuexLocal.plugin]
})

function parseJwt (token) {
    if(token === "" || token == null)
        return null;

    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}
