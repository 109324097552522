<template>
    <v-data-table
        :headers="headers"
        :items="hermanos"
        class="clickable-row"
        @click:row="clickAlumno"
    >
    </v-data-table>
</template>

<script>
export default {
    props: {
        alumno: Object,
        alumnos: Array
    },
    data: () => ({
        headers: [
            { text: "CURSO", value: "CURSO", sortable: false, align: "center" },
            { text: "N", value: "N°", sortable: false, align: "center" },
            { text: "NOMBRE", value: "ALUMNES", sortable: false },
            { text: "DNI", value: "DNI", sortable: false, align: "center" },
            { text: "TELEFONOS", value: "TELEFONOS", sortable: false, align: "center" },
            { text: "DIRECCION", value: "DIRECCION", sortable: false },
            { text: "ESTADO", value: "ESTADO", sortable: false, align: "center" }
        ],
    }),
    computed: {
        hermanos() {
            let hermanos = [];
            if (this.alumno["HERMANOS"] === undefined)
                return [];

            console.log("asd");

            const delimiters = /[\s,]+/;
            let hermanosIDs = this.alumno["HERMANOS"].split(delimiters);
            for (let i = 0; i < this.alumnos.length; i++) {
                let hermano = this.alumnos[i];
                if (hermanosIDs.includes(hermano["ID"])) {
                    hermanos.push(hermano);
                }
            }
            return hermanos;
        }
    },
    methods: {
        clickAlumno(item) {
            this.$router.push({ name: "alumno", params: { id: item["ID"] } });
        }
    }
}
</script>

<style>

</style>